<template>
  <div class="us-map-chart">
    <highcharts
      :constructor-type="'mapChart'"
      :options="mapOptions"
      class="map"
    ></highcharts>
    <v-card class="countryInfos pa-2">
      <div v-if="usaData.length">
        <div class="my-2">
          <span class="font-weight-bold"> {{ selectedCity.name }}</span>
        </div>

        <div class="city-name font-weight-bold my-3">Audience Count</div>
        <div
          class="city-infos-cats mt-1"
          v-for="(item, index) in usaData[0].categories_count"
          :key="'Categories' + index"
        >
          <span>{{ item.category_name }} : {{ item.category_count }}</span>
          <br />
        </div>
        <div class="city-name font-weight-bold my-3">
          Top Domains
        </div>
        <div
          class="city-infos-cats mt-1"
          v-for="(item, index) in usaData[0].domains"
          :key="'Domaine' + index"
        >
          <a target="_blank" :href="'http://'+item.split(':')[0]">{{ item.split(':')[0]}}</a>
          <span>: {{item.split(':')[1]}}</span>
          <br />
        </div>
        <div class="city-name font-weight-bold my-3">Classifications</div>
        <div
          class="city-infos-cats mt-1"
          v-for="(item, index) in usaData[0].classifications"
          :key="'class1' + index"
        >
         
          <span v-for="(item, i) in usaData[0].classifications[index]"
          :key="'class2' + i">{{ item }},</span>
          <br>
        </div>
      </div>
      <div v-else>
        <p class="city-infos-cats mt-5">No data are available</p>
      </div>
    </v-card>
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";
import worldMap from "@highcharts/map-collection/custom/usa-and-canada.geo.json";

export default {
  components: {
    highcharts: Chart,
  },
  data() {
    return {
      allUsaData: [],
      usaData: [],
      approch: this.$store.getters.getApproch,
      tier: this.$store.getters.getSegBtn,
      selectedCity: {
          name:''
      },
    };
  },
  created() {
    this.getUsaData();
  },
  methods: {
    async getUsaData() {
      await this.$http
        .post(
          "/sharethis_us_map?clustering_technique=" +
            this.approch +
            "&tier=" +
            this.tier
        )
        .then((res) => {
          console.log("/sharethis_map_data",res.data[0].location);
          this.allUsaData = res.data;
          this.loadInfos(res.data[0].location);
          
        })
        .catch((err) => {
          console.log(err);
        });
    },
    loadInfos(city) {
      this.usaData = this.allUsaData.filter(
        (el) =>{
           if(el.location.toUpperCase() == "CA"){
             this.selectedCity.name = "California"
             return el
           }}
      );
      console.log("usaData=>", this.usaData);
      console.log("city=>", city);
    },
    getInfos(e) {
    console.log(e.point);
    this.$store.commit("setCity", e.point['hc-key'].substring(3).toUpperCase());
    console.log(e.point.name.substring(3));
      this.selectedCity.name = e.point.name;
      this.usaData = this.allUsaData.filter((el) => el.location.toUpperCase() == e.point['hc-key'].substring(3).toUpperCase());
    },
  },
  computed: {
    mapOptions: function () {
      const that = this;
      return {
        chart: {
          height:600,
          map: worldMap,
          zoomType: "",
        },
        zomm: {
          enabled: false,
        },
        tooltip: {
          enabled: false,
        },
        navigator: {
          enabled: false,
        },
        title: {
          text: "",
        },
        subtitle: {
          text: "",
        },
        mapNavigation: {
                enabled: true,
                buttonOptions: {
                    verticalAlign: 'bottom'
                }
            },
        colorAxis: {
                min: 1,
                max: 5508520,
                minColor: '#ffffff',
                maxColor: '#76448A'
            },
        series: [
          {
            events: {
              click: function (e) {
                that.getInfos(e);
              }
            },
            name: "Random data",
            states: {
               hover: {
                color: "#512E5F"
              }
            },
            dataLabels: {
              enabled: false,
              format: "{point.name}",
            },
            allAreas: false,
            data: [
              ["us-ma", 1053213],
              ["us-wa", 3024724],
              ["us-ca", 5004332],
              ["us-or", 2085818],
              ["us-wi", 1449908],
              ["us-me", 1008898],
              ["us-mi", 1059789],
              ["us-nv", 1904055],
              ["us-nm", 1007295],
              ["us-co", 2423438],
              ["us-wy", 1076264],
              ["us-ks", 1016700],
              ["us-ne", 1028904],
              ["us-ok", 1074783],
              ["us-mo", 1061878],
              ["us-il", 1094740],
              ["us-in", 1053751],
              ["us-vt", 1554634],
              ["us-ar", 2072786],
              ["us-tx", 4089854],
              ["us-ri", 1085743],
              ["us-al", 1086393],
              ["us-ms", 1030986],
              ["us-nc", 1097014],
              ["us-va", 3099317],
              ["us-ia", 1275785],
              ["us-md", 1021438],
              ["us-de", 1033116],
              ["us-pa", 1920493],
              ["us-nj", 3088887],
              ["us-ny", 5508520],
              ["us-id", 1092684],
              ["us-sd", 1509396],
              ["us-ct", 1053662],
              ["us-nh", 1789439],
              ["us-ky", 1032832],
              ["us-oh", 2077679],
              ["us-tn", 3001263],
              ["us-wv", 2535924],
              ["us-dc", 1089351],
              ["us-la", 1097178],
              ["us-fl", 2521600],
              ["us-ga", 1039980],
              ["us-sc", 2050185],
              ["us-mn", 1073188],
              ["us-mt", 1000354],
              ["us-nd", 1041436],
              ["us-az", 2294712],
              ["us-ut", 1019075],
              ["us-hi", 1028415],
              ["us-ak", 1065289],
            ],
          },
        ],
      };
    },
  },
};
</script>

<style lang="scss">
.highcharts-credits {
  display: none;
}
.city-infos-cats * {
  font-size: 12px !important;
  font-weight: 500 !important;
}
.city-name {
  font-size: 12px !important;
}
.countryInfos {
  font-size: 15px !important;
}
.us-map-chart {
  .countryInfos {
    position: absolute;
    width: 30%;
    left: 0;
    top: 20em;
  }
  .map {
    min-height: 500px;
  }
}
</style>